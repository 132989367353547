import React, { useState, useEffect } from 'react'
import ImageComponent from '@/components/Image/Image'

interface ProgressiveImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  lowSrc: string
  highSrc: string
  lowWebp: string
  highWebp: string
  width?: string
  height?: string
}

const ProgressiveImage: React.FC<ProgressiveImageProps> = ({
  lowSrc,
  highSrc,
  lowWebp,
  highWebp,
  alt = '',
  className = '',
  style = {},
  width = '',
  height = '',
  ...rest
}) => {
  const [src, setSrc] = useState(lowSrc)
  const [webp, setWebp] = useState(lowWebp)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const img = new Image()
    img.src = highSrc
    img.onload = () => {
      setSrc(highSrc)
      setWebp(highWebp)
      setLoading(false)
    }
  }, [highSrc])

  return (
    <ImageComponent
      className={className}
      width={width}
      height={height}
      src={src}
      webp={webp}
      alt={alt}
      style={{
        filter: loading ? 'blur(10px)' : 'none',
        transition: 'filter 0.3s ease-out',
        ...style,
      }}
      {...rest}
    />
  )
}

export default ProgressiveImage
