import React, { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import initAnswersDemo from '@/helpers/initAnswersDemo'

export const DemoQuiz = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const test = searchParams.get('test')

  useEffect(() => {
    if (test === 'demo') initAnswersDemo(navigate)
  }, [test])

  return <div>Demo Quiz Init</div>
}
