import React from 'react'
import ReactDOM from 'react-dom/client'
import '@/assets/scss/style.scss'
import { BrowserRouter as Router, Routes, Navigate, Route } from 'react-router-dom'
import Quiz from '@/components/Quiz/Quiz'
import Paywall from '@/components/Paywall/Paywall'
import i18n from './i18n'
import CreateAccount from '@/components/CreateAccount/CreateAccount'
import { FAQ } from './components/pages/FAQ/FAQ'
import { PrivacyPolicy } from './components/pages/PrivacyPolicy/PrivacyPolicy'
import { MoneyBackPolicy } from './components/pages/MoneyBackPolicy/MoneyBackPolicy'
import { Terms } from './components/pages/Terms/Terms'
import ThankYou from './components/ThankYou/ThankYou'
import { ConfirmEmail } from './components/ConfirmEmail'
import ReactPixel from 'react-facebook-pixel'
import * as amplitude from '@amplitude/analytics-browser'
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser'
import { Profitwell } from '@/components/Profitwell'
import { AMPLITUDE_RATE, HOMEPAGE_PATH } from './constants/variables'
import { QueryClient, QueryClientProvider } from 'react-query'
import ErrorBoundary from '@/components/ErrrosBoundary'
import { TestError } from '@/components/pages/TestError/TestError'
import { StartScreen } from '@/components/QuestionsUI/StartScreen'
import { DemoQuiz } from '@/components/pages/DemoQuiz/DemoQuiz'

ReactPixel.init(process.env.REACT_APP_FB_PIXEL_ID ?? '')
ReactPixel.fbq('dataProcessingOptions', ['LDU'], 1, 0)

amplitude.init('b2a79bfa738450505fcce812db525ad1', { defaultTracking: true })

const sessionReplayTracking = sessionReplayPlugin({ sampleRate: AMPLITUDE_RATE })
amplitude.add(sessionReplayTracking)

document.documentElement.lang = i18n.language

const queryParams = new URLSearchParams(window.location.search)

sessionStorage.setItem('quizVersion', 'true')

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <ErrorBoundary>
    <QueryClientProvider client={queryClient}>
      <Router basename={HOMEPAGE_PATH}>
        <Routes>
          <Route
            path="/:currentStepString/:currentQuestionString"
            Component={(props) => (
              <Quiz
                startPath="/start"
                mainPath="/"
                nextPath="/0/0"
                {...props}
                lang={i18n.language}
                quizFile="quiz.json"
              />
            )}
          />
          <Route path="/start/" Component={(props) => <StartScreen nextPath="/0/0" {...props} />} />
          <Route
            path="/"
            element={<Navigate to={`/start/${queryParams ? `?${queryParams.toString()}` : ''}`} />}
          />
          <Route
            path="/paywall/"
            Component={(props) => (
              <Paywall
                paymentMethod={'stripe'}
                paywallFile={`${process.env.REACT_APP_MODE !== 'dev' ? 'paywall.json' : 'paywall-dev.json'}`}
                {...props}
                lang={i18n.language}
              />
            )}
          />
          <Route path="/confirm-email/" Component={(props) => <ConfirmEmail {...props} />} />
          <Route path="/profitwell/" Component={(props) => <Profitwell {...props} />} />
          <Route path="/register/" Component={(props) => <CreateAccount {...props} />} />
          <Route path="/faq/" Component={(props) => <FAQ {...props} />} />
          <Route path="/privacy-policy/" Component={(props) => <PrivacyPolicy {...props} />} />
          <Route path="/money-back/" Component={(props) => <MoneyBackPolicy {...props} />} />
          <Route path="/terms/" Component={(props) => <Terms {...props} />} />
          <Route path="/thank-you/" Component={(props) => <ThankYou {...props} />} />
          <Route
            path="/test-register/"
            Component={(props) => <CreateAccount testMode={true} {...props} />}
          />
          <Route path="/test-error" Component={() => <TestError />} />
          <Route path="/demo-quiz" Component={() => <DemoQuiz />} />
        </Routes>
      </Router>
    </QueryClientProvider>
  </ErrorBoundary>,
)
